@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap);
@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(/static/media/FuturaPTDemiOblique.63ead96d.otf) format('opentype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

